import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
//import ReactGA from 'react-ga';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import ImageGallery from 'react-image-gallery';
import { ToastContainer } from 'react-toastify';
import ContentHeade from '../../components/ContentHeader';
import Skeleton from '../../components/Skeleton';

import { api, apiId, urlImgs, urlSite, moneyFormatter, existsOrError, IsEmail, isMobile, notify, titleSite, reloadTime, urlFavicon } from '../../utils';

import SmFoto from '../../assets/img/sm-foto.jpg';
import Place from '../../assets/img/place.svg'

export default function Imovel(props) {   
    
    const ancor = useRef(null);    

    const storage = useSelector(state => state.data);
    const isOpen = useSelector(state => state.open);
    const isActive = useSelector(state => state.active);
    
    const [ showActions, setShowActions ] = useState(false);
    const [ showProposta, setShowProposta ] = useState(false);
    const [ showTelefones, setShowTelefones ] = useState(false);
    const [ pageSkeleton, setPageSkeleton ] = useState(true);
    const [ imovel, setImovel ] = useState({});
    const [ destaque, setDestaque ] = useState('');
    const [ dadosAnunciante, setDadosAnunciante ] = useState({});

    useEffect(() => {
        getDados();
        window.scroll({ top: (ancor.current.offsetTop + 50), left: 0, behavior: 'smooth' });
        if (Object.keys(storage).length > 0) {
            setDadosAnunciante(storage.anunciante);
        }
        setShowActions(isActive);
        //ReactGA.initialize(gaId, {debug: false});
        //ReactGA.pageview(props.location.pathname);
    },[]);

    function getDados() {
        api.post('',{                    
            acoes: [                        
                { 
                    metodo: "dadosimovel", 
                    params: [{ registro: props.match.params.id }] 
                },                
            ],
            id: apiId                
        }).then(resp => {              
            Object.keys(resp.data.dadosimovel).includes('erro') && props.history.push(`/404`);
            setImovel(resp.data.dadosimovel);            
            setFormulario({ ... formulario, lnk_anuncio: `${urlSite}/imovel/${resp.data.dadosimovel.id}` }); 
            setTimeout(() => {setPageSkeleton(false)}, 100);
        }).catch(e => {
            setTimeout(() => { getDados() }, reloadTime);
            console.log(e);
        });        
    }

    const refStorage = useRef(true);
    useEffect(() => {
        if (refStorage.current) {
            refStorage.current = false;            
            return;
        }
        setDadosAnunciante(storage.anunciante);
    },[storage]);

    const refActive = useRef(true);
    useEffect(() => {
        if (refActive.current) {
            refActive.current = false;            
            return;
        }
        setShowActions(isActive);        
    },[isActive])

    const refImovel = useRef(true);
    useEffect(() => {
        if (refImovel.current) {
            refImovel.current = false;            
            return;
        }
        
    },[imovel])
    
    let images;
    if ( imovel.imagens && imovel.imagens.length) {
        images = imovel.imagens.map(item => {
            return(
                {
                    original: `${urlImgs}/${item.imagem}`,
                    thumbnail: `${urlImgs}/${item.thumb}`,
                    originalTitle : `${item.titulo}`,                            
                }
            )
        });    
    }

    function handleClickMap() {
        window.open(`https://www.google.com/maps?q=${imovel.latitude},${imovel.longitude}`,'_blank');
    }

    const [ loading, setLoading ] = useState(false);

    const [ formulario, setFormulario ] = useState({ 
        nomecompleto: '',
        email: '',
        cidade: '',
        uf: '',
        telefone: '', 
        mensagem: '',
        imovel: imovel.id,
        finalidade: imovel.finalidade,
        lnk_anuncio: `${urlSite}/imovel/${imovel.id}`
    });

    const [ validate, setValidate ] = useState({ validateName: true,validateEmail: true,validateTel: true,validateCity: true,validateUf: true,validateMensage: true });

    async function handleSubmit() {        

        if (!existsOrError(formulario.nomecompleto) || !existsOrError(formulario.email) || !existsOrError(formulario.cidade) || !existsOrError(formulario.uf) || !existsOrError(formulario.telefone) || !existsOrError(formulario.mensagem)) {
            
            const camposinvalidos = {
                validateName: existsOrError(formulario.nomecompleto) ? true : false,
                validateEmail: existsOrError(formulario.email) ? true : false,
                validateCity: existsOrError(formulario.cidade) ? true : false, 
                validateUf: existsOrError(formulario.uf) ? true : false,  
                validateTel: existsOrError(formulario.telefone) ? true : false, 
                validateMensage: existsOrError(formulario.mensagem) ? true : false, 
            }

            setValidate({...validate, ...camposinvalidos});               
            notify('erro', 'Digite os campos obrigatórios'); 
            
        } else if (!IsEmail(formulario.email)) {
            
            setValidate({ validateName: true, validateEmail: false, validateTel: true, validateCity: true, validateUf: true, validateMensage: true });
            notify('erro', 'Digite um e-mail válido');
            
        } else if (!isMobile(formulario.telefone)) {

            setValidate({ validateName: true, validateEmail: true, validateTel: false, validateCity: true, validateUf: true, validateMensage: true });
            notify('erro', 'Digite um celular válido');

        } else {            
            setLoading(true); 
            setValidate({ validateName: true, validateEmail: true, validateTel: true, validateCity: true, validateUf: true, validateMensage: true });

            api.post('',{                    
                acoes: [                        
                    { 
                        metodo: "enviarproposta", 
                        params: [{ ...formulario }] 
                    },                
                ],
                id: apiId                 
            }).then(resp => {                        
                if (resp.data.proposta.status === 'erro') {
                    notify('erro', resp.data.proposta.erro);
                } 
                else if (resp.data.proposta.status === 'sucesso') {
                    notify('sucesso', 'Mensagem enviada com sucesso');
                    setFormulario({ nomecompleto: '',email: '',cidade: '',uf: '',telefone: '', mensagem: '' });
                }
                setLoading(false); 
            }).catch(e => {
                notify('erro', 'Ocorreu um erro inesperado, tente novamente mais tarde');
                setLoading(false);
            });

        }

    }

    function handleForm(valores) {
        setFormulario({ ...formulario, ...valores });
    }

    function handleClose(value) {
        value === 'telefones' && setShowTelefones(false); 
        value === 'proposta' && setShowProposta(false);
    }
    function handleShow(value) {
        value === 'telefones' && setShowTelefones(true); 
        value === 'proposta' && setShowProposta(true);
    }    
    
    return (
        <>
            <Helmet>

                <link rel="apple-touch-icon" sizes="57x57" href={ `${urlFavicon}apple-icon-57x57.png`} />
                <link rel="apple-touch-icon" sizes="60x60" href={ `${urlFavicon}apple-icon-60x60.png` } />
                <link rel="apple-touch-icon" sizes="72x72" href={ `${urlFavicon}apple-icon-72x72.png` } />
                <link rel="apple-touch-icon" sizes="76x76" href={ `${urlFavicon}apple-icon-76x76.png` } />
                <link rel="apple-touch-icon" sizes="114x114" href={ `${urlFavicon}apple-icon-114x114.png` } />
                <link rel="apple-touch-icon" sizes="120x120" href={ `${urlFavicon}apple-icon-120x120.png` } />
                <link rel="apple-touch-icon" sizes="144x144" href={ `${urlFavicon}apple-icon-144x144.png` } />
                <link rel="apple-touch-icon" sizes="152x152" href={ `${urlFavicon}apple-icon-152x152.png` } />
                <link rel="apple-touch-icon" sizes="180x180" href={ `${urlFavicon}apple-icon-180x180.png` } />
                <link rel="icon" type="image/png" sizes="192x192"  href={ `${urlFavicon}android-icon-192x192.png` } />
                <link rel="icon" type="image/png" sizes="32x32" href={ `${urlFavicon}favicon-32x32.png` } />
                <link rel="icon" type="image/png" sizes="96x96" href={ `${urlFavicon}favicon-96x96.png` } />
                <link rel="icon" type="image/png" sizes="16x16" href={ `${urlFavicon}favicon-16x16.png` } />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content={ `${urlFavicon}ms-icon-144x144.png` } />
                <meta name="theme-color" content="#ffffff" />

                <meta name="description" content={imovel.maisdescricao} />
                <meta name="og:site_name" property="og:site_name" content={titleSite} />
                <meta name="og:url" property="og:url" content={`${urlSite}/imovel/${imovel.id}`} />
                <meta name="og:title" property="og:title" content={`${imovel.titulo} | ${imovel.tipo} | ${imovel.finalidade} | Imóvel | ${ titleSite }`} />
                <meta name="og:description" property="og:description" content={imovel.maisdescricao} />                             
                <meta name="og:image" property="og:image" content={`${urlImgs}/${destaque}`} />
                <meta name="og:image:width" property="og:image:width" content="640" />
                <meta name="og:image:height" property="og:image:height" content="480" />
                <title>{ Object.keys(imovel).length > 0 ? `${imovel.titulo} | ${imovel.tipo} | ${imovel.finalidade} | Imóvel | ${titleSite} ` : `${titleSite}`}</title>
            </Helmet> 

            <div ref={ancor} className={`${isOpen ? 'open ': ''}main`}> 
            
                <ContentHeade title="Imóvel" routes={props} />
            
                <div className="container visualizacao px-4 px-sm-0">        
                    
                    <header className={`${ pageSkeleton ? 'd-flex ' : 'd-none '}justify-content-between align-items-center pt-5`}>
                        <Skeleton width={280} height={24} />                    
                    </header>

                    <header className={`${ pageSkeleton ? 'd-none ' : 'd-flex '}topo-visualizacao justify-content-between align-items-center pt-5`}>
                        { imovel.titulo && (
                            <div className="font-16 titulo pr-5">
                                <span className="pr-2">{ imovel.titulo }</span>
                            </div>
                        ) }                        
                    </header>
                    
                    
                        <div className="row py-5">
                            <div className="col-12 col-lg-7">

                                <div className="redes-sociais d-flex d-lg-none" style={{ transform: 'translate(-15px, -115%)' }}>
                                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${urlSite}/imovel/${imovel.id}`} className="facebook mx-0" target="_blank" rel="noopener noreferrer nofollow">Facebook</a>   
                                    <a href={`https://twitter.com/intent/tweet?text=${urlSite}/imovel/${imovel.id}`} className="instagram mx-1" target="_blank" rel="noopener noreferrer nofollow">Instagram</a>
                                    <a href={`https://api.whatsapp.com/send?text=${urlSite}/imovel/${imovel.id}`} className="whatsapp mx-0" target="_blank" rel="noopener noreferrer follow">Whatsapp</a>
                                </div>
                                
                                {
                                    (
                                        images ?
                                            images.length == 1 ?
                                                <div><img src={images[0].original} /></div>
                                            : <ImageGallery lazyLoad={true} showFullscreenButton={true} items={images} useBrowserFullscreen={ false } />
                                        
                                        : <div><img src={SmFoto} /></div>
                                    )
                                }

                                <div className="pt-3 pb-4 d-block d-lg-none">                                

                                    <Skeleton className={`${ pageSkeleton ? 'd-block ' : 'd-none '}`} width={150} height={20} />
                                    <div className=  {`${ pageSkeleton ? 'd-none ' : 'd-block '}text-uppercase font-12 color-active`} color={'#8B303F!important'}>{ `${imovel.finalidade} - ${imovel.tipo}` }</div>
                                    
                                    <Skeleton className={`${ pageSkeleton ? 'd-block ' : 'd-none '}my-2`} width={210} height={38} />
                                    <div className={`${ pageSkeleton ? 'd-none ' : 'd-block '}font-32`}><b>{ imovel.valor ? `R$ ${moneyFormatter(imovel.valor)}` : 'Consulte-nos' }</b></div>
                                    
                                    { pageSkeleton &&  <Skeleton className={`my-2`} width={180} height={38} /> }
                                    
                                    { (imovel.valor_condominio && !pageSkeleton ) && <div className="font-12">{ `Condomínio: R$ ${moneyFormatter(imovel.valor_condominio)}` }</div> }
                                    { (imovel.valor_iptu && !pageSkeleton ) && <div className="font-12">IPTU: {imovel.valor_iptu}</div> }
                                    
                                    { (imovel.areatotal && imovel.areaconstruida && !pageSkeleton) && (
                                        <div className="font-12 opacity-75 pt-2">
                                            Área Total: {imovel.areatotal} m<sup>2</sup> / Construída: {imovel.areaconstruida} m<sup>2</sup>                                        
                                        </div>  
                                    )}

                                </div>

                                <div className="d-block d-lg-none">
                                    <div className={`${ pageSkeleton ? 'd-block ' : 'd-none '}border-top border-bottom py-3`}>
                                        <Skeleton  width={`100%`} height={37} />
                                    </div>

                                    <div className={`${ pageSkeleton ? 'd-none ' : 'd-flex '}border-top border-bottom py-3 flex-column flex-md-row`}>
                                        <div className="endereco d-flex align-items-center flex-grow-1 pr-4 font-14 line-height-130">
                                            <img src={Place} alt="" />
                                            {`${imovel.endereco ? `${imovel.endereco} - ` : '' }${imovel.bairro} | ${imovel.cidade}/${imovel.uf}`}
                                        </div>
                                        { (imovel.latitude && imovel.longitude) && <button type="button" onClick={() => handleClickMap()} className="btn btn-secondary shadow-sm text-white font-13 px-4 py-2 m-0 mt-3 mt-md-0"><b>VER NO MAPA</b></button> }
                                    </div>
                                </div>

                                <div className="d-block d-lg-none">
                                { imovel.caracteristicas && (
                                    <>
                                    <div className={`${ pageSkeleton ? 'd-block ' : 'd-none '}pt-4`}> 
                                        <Skeleton  width={`100%`} height={40} />
                                    </div>

                                    <div className={`${ pageSkeleton ? 'd-none ' : 'd-block '}pt-4 font-14`}> 
                                        <ul>
                                            { imovel.caracteristicas.map((item, index) => <li key={index}>{item}</li> ) }
                                        </ul>
                                    </div>
                                    </>
                                ) }  
                                </div>

                                { imovel.maisdescricao && (
                                    <>
                                    <div className={`${ pageSkeleton ? 'd-block ' : 'd-none '}pt-4`}>
                                        <div>
                                            <Skeleton width={`100%`} height={20} /> 
                                            <Skeleton className="mt-2" width={`100%`} height={63} /> 
                                        </div>
                                    </div>

                                    <div className={`${ pageSkeleton ? 'd-none ' : 'd-block '}descricao pt-4`}>
                                        <div className="d-flex border-bottom mb-3"><h2 className="font-14 border-bottom py-2 color-active mb-0">DESCRIÇÃO DO IMÓVEL</h2></div>
                                        <div className="font-14">{ imovel.maisdescricao }</div>
                                    </div>
                                    </>
                                )}

                                { imovel.observacoes && (
                                    <>
                                    <div className={`${ pageSkeleton ? 'd-block ' : 'd-none '}pt-4`}>
                                        <div>
                                            <Skeleton width={`100%`} height={20} /> 
                                            <Skeleton className="mt-2" width={`100%`} height={63} /> 
                                        </div>
                                    </div>

                                    <div className={`${ pageSkeleton ? 'd-none ' : 'd-block '}descricao pt-4`}>
                                        <div className="d-flex border-bottom mb-3"><h2 className="font-14 border-bottom py-2 color-active mb-0">OBSERVAÇÃO</h2></div>
                                        <div className="font-14">{ imovel.observacoes }</div>
                                    </div>
                                    </>
                                ) }

                                { imovel.infraestrutura && (
                                    <>
                                    <div className={`${ pageSkeleton ? 'd-block ' : 'd-none '}pt-4`}>
                                        <div>
                                            <Skeleton width={`100%`} height={20} /> 
                                            <Skeleton className="mt-2" width={`100%`} height={63} /> 
                                        </div>
                                    </div>

                                    <div className={`${ pageSkeleton ? 'd-none ' : 'd-block '}descricao pt-4`}>
                                        <div className="d-flex border-bottom mb-3"><h2 className="font-14 border-bottom py-2 color-active mb-0">TEM NAS PROXIMIDADES</h2></div>
                                        <div className="font-14">
                                            <ul>
                                                { imovel.infraestrutura.map((item, index) => <li key={index}>{item}</li> ) }
                                            </ul>
                                        </div>
                                    </div>
                                    </>
                                ) }

                            </div>
                            <div className="col-12 col-lg-5">
                                
                                <div className="pb-4 d-none d-lg-block">
                                    <div className="redes-sociais d-flex">
                                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${urlSite}/imovel/${imovel.id}`} className="facebook mx-0" target="_blank" rel="noopener noreferrer nofollow">Facebook</a>   
                                        <a href={`https://twitter.com/intent/tweet?text=${urlSite}/imovel/${imovel.id}`} className="instagram mx-1" target="_blank" rel="noopener noreferrer nofollow">Instagram</a>
                                        <a href={`https://api.whatsapp.com/send?text=${urlSite}/imovel/${imovel.id}`} className="whatsapp mx-0" target="_blank" rel="noopener noreferrer follow">Whatsapp</a>
                                    </div>

                                    <Skeleton className={`${ pageSkeleton ? 'd-block ' : 'd-none '}`} width={150} height={20} />
                                    <div className={`${ pageSkeleton ? 'd-none ' : 'd-block '}text-uppercase font-12  color-active`}>{ `${imovel.finalidade} - ${imovel.tipo}` }</div>
                                    
                                    <Skeleton className={`${ pageSkeleton ? 'd-block ' : 'd-none '}my-2`} width={210} height={38} />
                                    <div className={`${ pageSkeleton ? 'd-none ' : 'd-block '}font-32`}><b>{ imovel.valor ? `R$ ${moneyFormatter(imovel.valor)}` : 'Consulte-nos' }</b></div>
                                    
                                    { pageSkeleton &&  <Skeleton className={`my-2`} width={180} height={38} /> }
                                    
                                    { (imovel.valor_condominio && !pageSkeleton ) && <div className="font-12">{ `Condomínio: R$ ${moneyFormatter(imovel.valor_condominio)}` }</div> }
                                    { (imovel.valor_iptu && !pageSkeleton ) && <div className="font-12">IPTU: {imovel.valor_iptu}</div> }
                                    
                                    { (imovel.areatotal && imovel.areaconstruida && !pageSkeleton) && (
                                        <div className="font-12 opacity-75 pt-2">
                                            Área Total: {imovel.areatotal} m<sup>2</sup> / Construída: {imovel.areaconstruida} m<sup>2</sup>                                        
                                        </div>
                                    )}

                                </div>
                                
                                <div className="d-none d-lg-block">
                                    <div className={`${ pageSkeleton ? 'd-block ' : 'd-none '}border-top border-bottom py-3`}>
                                        <Skeleton  width={`100%`} height={37} />
                                    </div>

                                    <div className={`${ pageSkeleton ? 'd-none ' : 'd-flex '}border-top border-bottom py-3`}>
                                        <div className="endereco d-flex align-items-center flex-grow-1 pr-4 font-14 line-height-130">
                                            <img src={Place} alt="" />
                                            {`${imovel.endereco ? `${imovel.endereco} - ` : '' }${imovel.bairro} | ${imovel.cidade}/${imovel.uf}`}
                                        </div>
                                        { (imovel.latitude && imovel.longitude) && <button type="button" onClick={() => handleClickMap()} className="btn btn-secondary shadow-sm w-50 text-white font-13 px-0 py-2 m-0"><b>VER NO MAPA</b></button> }
                                    </div>
                                </div>
                                
                                <div className="d-none d-lg-block">
                                { imovel.caracteristicas && (
                                    <>
                                    <div className={`${ pageSkeleton ? 'd-block ' : 'd-none '}pt-4`}> 
                                        <Skeleton  width={`100%`} height={40} />
                                    </div>

                                    <div className={`${ pageSkeleton ? 'd-none ' : 'd-block '}pt-4 font-14`}> 
                                        <ul>
                                            { imovel.caracteristicas.map((item, index) => <li key={index}>{item}</li> ) }
                                        </ul>
                                    </div>
                                    </>
                                ) }  
                                </div>                          

                                <div className="d-none d-md-block border p-4 p-md-5 mt-4">
                                    <h2 className="font-24 m-0 p-0">Entre em Contato</h2> 
                                    <p className="font-12 opacity-75">Preencha o formulário abaixo</p>
                                    
                                    <form>                                                                                
                                        <div className="row mx-0 font-14">

                                            <div className="col-12 pb-2 mb-1 px-0">
                                                <input type="text" className={ !validate.validateName ? 'is-invalid' : '' } placeholder="NOME COMPLETO" value={formulario.nomecompleto} onChange={(e) => handleForm({ nomecompleto: e.target.value })} />
                                            </div>
                                            <div className="col-12 px-0 pb-2 mb-1">
                                                <input type="email" className={ !validate.validateEmail ? 'is-invalid' : '' } placeholder="E-MAIL" value={formulario.email} onChange={(e) => handleForm({email: e.target.value})} />
                                            </div>                                        
                                            <div className="col-2 px-0 pb-2 mb-1">                                            
                                                <input type="text" placeholder="UF" maxLength="2" className={ !validate.validateUf ? 'is-invalid text-uppercase' : 'text-uppercase'} value={formulario.uf} onChange={(e) => handleForm({uf: e.target.value})} /> 
                                            </div>
                                            <div className="col-10 px-0 pb-2 mb-1">
                                                <input type="text" className={ !validate.validateCity ? 'is-invalid' : '' } placeholder="CIDADE" value={formulario.cidade} onChange={(e) => handleForm({cidade: e.target.value})} />
                                            </div>
                                            <div className="col-12 px-0 pb-2 mb-1">
                                                <NumberFormat className={ !validate.validateTel ? 'is-invalid' : '' } placeholder="CELULAR" format="(##) #####-####" mask="_" value={formulario.telefone} onChange={(e) => handleForm({telefone: e.target.value})} />
                                            </div>
                                            <div className="col-12 px-0 pb-2 mb-1">
                                                <textarea className={ !validate.validateMensage ? 'is-invalid' : '' } placeholder="MENSAGEM" value={formulario.mensagem} onChange={(e) => handleForm({mensagem: e.target.value})}></textarea>
                                            </div>
                                            <div className="col-12 pt-4 px-0 d-flex justify-content-end">
                                                <button type="button" onClick={() => handleSubmit()} className="btn btn-primary font-14 py-2 px-5 shadow-sm" disabled={ loading ? true : false }>
                                                    { loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> }
                                                    ENVIAR
                                                </button>  
                                            </div>

                                        </div>                                        
                                    </form>

                                </div>

                            </div>
                        </div>    
                    
                    
                </div>

            </div>

            <div className={`${isOpen ? 'open ': ''}d-flex d-md-none justify-content-center nav-bottom${showActions ? ' show' : ''}`}>
                <div className="pb-4">
                    { (Object.keys(dadosAnunciante).length > 0 && Object.keys(dadosAnunciante).includes('telefones')) && (
                        <button type="button" onClick={() => handleShow('telefones')} className="btn-telefone border-0 mx-2">Telefones</button>
                    ) }                    
                    <button type="button" onClick={() => handleShow('proposta')} className="btn-proposta btn-primary border-0 mx-2">Enviar Proposta</button>
                </div>                
            </div>

            <Modal className="modal-style" centered show={showTelefones} onHide={() => handleClose('telefones')}>          
                <Modal.Body className="p-4">          
                    <h2 className="font-24 mb-3 p-0">Telefones</h2> 
                    <div className="lista-telefones"> 
                        
                        { (Object.keys(dadosAnunciante).length > 0 && Object.keys(dadosAnunciante).includes('telefones')) && dadosAnunciante.telefones.map((tel, index) => (
                            <a href={tel.app !== '0' ? `https://api.whatsapp.com/send?l=pt-BR&amp;phone=55${tel.ddd}${tel.numero.replace('-','')}&text=Oi,%20vim%20pelo%20seu%20site!` : `tel:0${tel.ddd}${tel.numero.replace('-','')}`} key={index} className={`${tel.app !== '0' ? 'whats ' : ''}d-block border p-3 my-4`} rel="noopener noreferrer nofollow" target="_blank"><span>{ `(${tel.ddd}) ${tel.numero}`}</span></a>
                        ))}

                    </div>
                                                                        
                </Modal.Body>
            </Modal>

            <Modal className="modal-style" centered show={showProposta} onHide={() => handleClose('proposta')}>          
                <Modal.Body className="p-4">          
                    <h2 className="font-24 m-0 p-0">Entre em Contato</h2> 
                    <p className="font-12 opacity-75">Preencha o formulário abaixo</p>
                    
                    <form>                                                                                
                        <div className="row mx-0 font-14">

                            <div className="col-12 pb-2 mb-1 px-0">
                                <input type="text" className={ !validate.validateName ? 'is-invalid' : '' } placeholder="NOME COMPLETO" value={formulario.nomecompleto} onChange={(e) => handleForm({ nomecompleto: e.target.value })} />
                            </div>
                            <div className="col-12 px-0 pb-2 mb-1">
                                <input type="email" className={ !validate.validateEmail ? 'is-invalid' : '' } placeholder="E-MAIL" value={formulario.email} onChange={(e) => handleForm({email: e.target.value})} />
                            </div>                                        
                            <div className="col-2 px-0 pb-2 mb-1">                                            
                                <input type="text" placeholder="UF" maxLength="2" className={ !validate.validateUf ? 'is-invalid text-uppercase' : 'text-uppercase'} value={formulario.uf} onChange={(e) => handleForm({uf: e.target.value})} /> 
                            </div>
                            <div className="col-10 px-0 pb-2 mb-1">
                                <input type="text" className={ !validate.validateCity ? 'is-invalid' : '' } placeholder="CIDADE" value={formulario.cidade} onChange={(e) => handleForm({cidade: e.target.value})} />
                            </div>
                            <div className="col-12 px-0 pb-2 mb-1">
                                <NumberFormat className={ !validate.validateTel ? 'is-invalid' : '' } placeholder="CELULAR" format="(##) #####-####" mask="_" value={formulario.telefone} onChange={(e) => handleForm({telefone: e.target.value})} />
                            </div>
                            <div className="col-12 px-0 pb-2 mb-1">
                                <textarea className={ !validate.validateMensage ? 'is-invalid' : '' } placeholder="MENSAGEM" value={formulario.mensagem} onChange={(e) => handleForm({mensagem: e.target.value})}></textarea>
                            </div>
                            <div className="col-12 pt-4 px-0 d-flex justify-content-end">
                                <button type="button" onClick={() => handleSubmit()} className="btn btn-primary font-14 py-2 px-5 shadow-sm" disabled={ loading ? true : false }>
                                    { loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> }
                                    ENTRAR
                                </button>  
                            </div>

                        </div>                                        
                    </form> 
                                                                        
                </Modal.Body>
            </Modal>

            <ToastContainer />
        </>
    );
}